import React from 'react';

type RowPropsType = {
  number: number;
  idx: string;
  city: string;
  street: string;
  price: number;
  square: number;
 
 }
 

const ObjectTabeleRow: React.FC<RowPropsType> = ({ number, idx, city, street, price,square}) => {
  return (
    <tr>
    <td>{number}</td>
    <td>{idx}</td>
    <td>{city}</td>
    <td>{street}</td>
    <td>{price}</td>
    <td>{square}</td>
    </tr>
  )
}

export default ObjectTabeleRow;