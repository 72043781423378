import React from "react";
import s from "./Navbar.module.css";
import {NavLink} from "react-router-dom";


const Navbar: React.FC = () => {
    return (
        <nav className={s.nav}>
            <div className={s.nav__container}>
                <div className={s.nav__item}>
                    <NavLink to="/profile" activeClassName={s.active}>Главная</NavLink>
                </div>
                <div className={s.nav__item}>
                    <NavLink to="/objects" activeClassName={s.active}>Объект</NavLink>
                </div>
                {<div className={s.nav__item}>
                    <NavLink to="/news" activeClassName={s.active}>Новости</NavLink>
                </div> }
                <div className={s.nav__item}> 
                    <NavLink to="/parser" activeClassName={s.active}>Парсер</NavLink>
                </div>
                <div className={s.nav__item}> 
                    <NavLink to="/object_status" activeClassName={s.active}>Статус_объектов</NavLink>
                </div>
            </div>
        </nav>
    );
}


export default Navbar;
