import React, { useState, useEffect } from "react";
import s from "./News.module.css";

type PropsType = {
    name: string;
};

type NewsEntry = {
    id?: number; // Добавляем id для уникального ключа
    title: string;
    description: string;
    picture: string | File;
    enabled?: boolean;
    isEditing: boolean;
};

const News: React.FC<PropsType> = (props) => {
    const [newsEntry, setNewsEntry] = useState<NewsEntry>({ title: '', description: '', picture: '', isEditing: false });
    const [newsList, setNewsList] = useState<NewsEntry[]>([]);
    const [formErrors, setFormErrors] = useState<{ [key in keyof NewsEntry]?: boolean }>({});

    useEffect(() => {
        fetchNews();
    }, []);


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setNewsEntry(prev => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setNewsEntry(prev => ({ ...prev, picture: file }));
        }
    };
    
    

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('title', newsEntry.title);
        formData.append('description', newsEntry.description);
        if (newsEntry.picture instanceof File) {
            formData.append('picture', newsEntry.picture);
        }
    
        if (newsEntry.isEditing && newsEntry.id) {
            await handleUpdateNews(formData, newsEntry.id);
        } else {
            await handleSendNews(formData);
        }
    
        setNewsEntry({ title: '', description: '', picture: '', isEditing: false });
        setFormErrors({});
    };
    
    // Добавление новости
    const handleSendNews = async (formData: FormData) => {
        try {
            const response = await fetch('https://homeho.ru/api/v1/room/news/create', {
                method: 'POST',
                body: formData,  
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const addedNews = await response.json();
            setNewsList([...newsList, {...addedNews, picture: addedNews.picture}]);
            alert('Новость успешно добавлена!');
        } catch (error) {
            console.error("Ошибка при добавлении новости: ", error);
            alert('Ошибка при добавлении новости.');
        }
    };
    
    //Обновить запись
    const handleUpdateNews = async (formData: FormData, newsId: number | undefined) => {
        try {
            const response = await fetch(`https://homeho.ru/api/v1/room/news/update/${newsId}`, {
                method: 'PUT',
                body: formData,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedNews = await response.json();
            setNewsList(currentNewsList => {
                const index = currentNewsList.findIndex(item => item.id === newsId);
                const updatedList = [...currentNewsList];
                updatedList[index] = { ...updatedList[index], ...updatedNews };
                return updatedList;
            });
            alert('Новость успешно обновлена!');
        } catch (error) {
            console.error("Ошибка при обновлении новости: ", error);
            alert(`Ошибка при обновлении новости:`);
        }
    };

    //Чекбокс
    const handleToggleEnabled = async (newsId: number | undefined, isEnabled: boolean | undefined) => {
        // try {
        //     const response = await fetch(`http://localhost:3030/v1/room/news/update/${newsId}`, {
        //         method: 'PUT',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({ enabled: !isEnabled })
        //     });
        //     if (!response.ok) {
        //         throw new Error(`HTTP error! status: ${response.status}`);
        //     }
        //     const toggledNews = await response.json();
        //     setNewsList(currentNewsList => {
        //         const index = currentNewsList.findIndex(item => item.id === newsId);
        //         const updatedList = [...currentNewsList];
        //         updatedList[index] = { ...updatedList[index], ...toggledNews };
        //         return updatedList;
        //     });
        //     alert('Статус новости обновлён!');
        // } catch (error) {
        //     console.error("Ошибка при обновлении статуса новости: ", error);
        //     alert('Ошибка при обновлении статуса новости.');
        // }
    };


    //Показывать все новости с бд
    const fetchNews = async () => {
        console.log('Запрос всех новостей'); 
        const response = await fetch('https://homeho.ru/api/v1/room/news/all');
        console.log('Ответ сервера на запрос новостей:', await response.clone().text()); 
        if (!response.ok) {
            alert(`Ошибка при получении новостей: ${response.statusText}`);
            return;
        }
        const allNews = await response.json();
        setNewsList(allNews);
    };
    
    
    const handleEdit = (news: NewsEntry) => {
        setNewsEntry({ ...news, isEditing: true });
    };

    const handleCancelEdit = () => {
        setNewsEntry({ title: '', description: '', picture: '', isEditing: false });
    };

    // Удаление
    async function handleDelete(newsId: number | undefined, index: number) {
        if (typeof newsId !== 'number') {
            console.error('News ID is undefined');
            return;
        }
        const response = await fetch(`https://homeho.ru/api/v1/room/news/delete/${newsId}`, { method: 'DELETE' });
        if (!response.ok) {
            alert(`Ошибка при удалении новости: ${response.statusText}`);
            return;
        }
        const newNewsList = [...newsList];
        newNewsList.splice(index, 1);
        setNewsList(newNewsList);
        alert('Новость удалена!');
    }
    

    const renderImage = (picture: string | Blob | MediaSource) => {
        if (typeof picture === 'string') {
            return `/images/${picture}`;
        }
        return URL.createObjectURL(picture);
    };
    
    

    return (
        <div className={s.container}>
            <h1>Форма для добавления новостей на главную страницу!</h1>
            <form onSubmit={handleSubmit} className={s.form}>
                <div className={s.inputGroup}>
                    <label>Заголовок:
                        <input type="text" name="title" value={newsEntry.title} onChange={handleInputChange} className={s.input} />
                    </label>
                    <label>Содержание:
                        <textarea name="description" value={newsEntry.description} onChange={handleInputChange} className={s.textarea} />
                    </label>
                    <label>Изображение:
                        <input type="file" accept="image/*" onChange={handleFileChange} className={s.input} />
                    </label>
                    <button type="submit" className={s.button}>
                        {newsEntry.isEditing ? 'Сохранить' : 'Добавить новость'}
                    </button>
                    {newsEntry.isEditing && <button type="button" onClick={handleCancelEdit} className={s.button}>Отменить</button>}
                </div>
            </form>
            <table className={s.table}>
               <thead>
                    <tr>
                        <th>Заголовок</th>
                        <th>Содержание</th>
                        <th>Изображение</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {newsList !== null && newsList.map((item, index) => (
                        <tr key={item.id}>
                            <td>{item.title}</td>
                            <td>{item.description}</td>
                            <td>
                                <img src={renderImage(item.picture)} alt="News" className={s.image} />
                            </td>
                            <td>
                                <button onClick={() => handleEdit(item)} className={s.button}>Редактировать</button>
                                <button onClick={() => handleDelete(item.id, index)} className={s.button}>Удалить</button>
                                <input
                                    type="checkbox"
                                    checked={item.enabled}
                                    onChange={() => handleToggleEnabled(item.id, item.enabled)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
    );
};

export default News;