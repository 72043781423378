import React from "react";
import s from "./FilterObject.module.css";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {Input} from "../../common/FormsControls/FormsControls";
// import {required} from "../../../utils/validators/validators";

// type PropsType = {

// }

type FilterObjectFormValuesType = {
    id: string | number
    country: string
}

const FilterObjectForm : React.FC<InjectedFormProps<FilterObjectFormValuesType>> = (props) => {
    return (
        <div className={s.container}>
            <span className={s.form__span}>Просмотр объектов</span>
            <form onSubmit={props.handleSubmit}>
                <div >
                    <div className={s.block__input}>
                        <span className={s.form__span}>ID объекта</span>
                        <Field className={s.form__input} placeholder={"id объекта"} name={"id"}
                               type={"id"}
                               component={Input}/>
                               {/*validate={[required]}*/}
                    </div>
                    <div className={s.block__input}>
                        <span className={s.form__span}>Введите город</span>
                        <Field className={s.form__input} placeholder={"город"} name={"country"}
                               type={"country"}
                               component={Input}
                                />
                    </div>
                    <div className={s.block__exit}>
                        <button className={s.exit}>Поиск</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

const FilterObjectReduxForm = reduxForm<FilterObjectFormValuesType>({form: "search"})(FilterObjectForm)

export default FilterObjectReduxForm;