import React from "react";
import ObjectTabele from "../ObjectTabele/ObjectTabele";
import s from "./Object.module.css";

type PropsType = {};

const testData = [
  {
    number: 1,
    idx: "R12EDR45",
    city: "Москва",
    street: "Московская улица",
    price: 21500000,
    square: 54,
  },
  {
    number: 2,
    idx: "B12FER56",
    city: "Санкт-Петербург",
    street: "Адмиралтейская улица",
    price: 22300000,
    square: 66,
  },
  {
    number: 3,
    idx: "N23CCR17",
    city: "Пенза",
    street: "Речная улица",
    price: 4700000,
    square: 44,
  },
];

const FilterObject: React.FC<PropsType> = (props) => {
  return (
    <div className={s.container}>
      <div>
        <ObjectTabele data={testData} />
      </div>
    </div>
  );
};

export default FilterObject;
