import React, { useEffect, useState } from "react";
import s from "./Parser.module.css";
import Axios from "axios";

type PropsType = {
    name: string
}

const Parser : React.FC<PropsType>  = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isGeoParser, setIsGeoParser] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [logsPerPage, setLogsPerPage] = useState(10);
    
    useEffect(() => {
      setIsLoading(true);
      // Axios.get('http://localhost:3030/v1/room/get-log-object-status')
      Axios.get('https://homeho.ru/api/v1/room/get-log-object-status')
        .then(response => {
          console.log(response.data, 'response.data');
          
          const sortedData = response.data.sort((a: any, b: any) => {
            let startTime: any = new Date(b.created_at);
            let endTime: any = new Date(a.created_at);
            return startTime - endTime;
          });
          setData(sortedData);
          setIsLoading(false);
        })
        .catch(error => {
          console.error(error);
          setIsLoading(false);
        });
    }, []);


    const indexOfLastLog = currentPage * logsPerPage;
    const indexOfFirstLog = indexOfLastLog - logsPerPage;
    const currentLogs = data.slice(indexOfFirstLog, indexOfLastLog);

    const renderLogs = currentLogs.map((log: any, index) => {
      let status;
      let ad_id;
      if (log.add_ad_id) {
        status = 'add';
        ad_id = log.add_ad_id;
      }
      if (log.del_ad_id) {
        status = 'delete';
        ad_id = log.del_ad_id;
      }

      return (
        <tr key={index}>
          <td>{ad_id}</td>
          <td>{status}</td>
          <td>{log.created_at}</td>
        </tr>
      );
    });
  
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / logsPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number, index) => {
      if (number === 1 || number === pageNumbers.length || Math.abs(currentPage - number) <= 1) {
        // show first, last, and adjacent pages
        return (
          <li
            key={index}
            className={`${s.pageNumber} ${
              currentPage === number ? s.active : ""
            }`}
          >
            <button onClick={() => setCurrentPage(number)}>{number}</button>
          </li>
        );
      } else if (index === 1 || index === pageNumbers.length - 2) {
        return <li key={index}>...</li>;
      } else {
        return null;
      }
    });
    

    return (
      <div className={s.container}>
        <div className={s.block__text}>
          <div className={s.text}>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>ad_id</th>
              <th>status</th>
              <th>created_at</th>
            </tr>
          </thead>
          <tbody>
            {renderLogs}
          </tbody>
        </table>
        <ul className={s.pageNumbers}>
          {renderPageNumbers}
        </ul>
      </div>
    );
  }
  
  export default Parser;