import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withAuthRedirect} from "../hoc/withAuthRedirect";
import Rooms from "./Rooms";
import {AppStateType} from "../../redux/redux-store";
import {searchObjectThunkCreator} from "../../redux/auth-reducer";



type MapDispatchPropsType = {
    searchObjectThunkCreator:(id: number | string, country: string) => void
}

type MapStatePropsType = {
    name: string
}

// type PropsType = MapStatePropsType

class RoomsContainer extends React.Component <MapStatePropsType & MapDispatchPropsType> {

    render () {
        return(
            <div>
                <Rooms name={this.props.name} searchObjectThunkCreator={this.props.searchObjectThunkCreator}/>
            </div>
        )
    }
}

let mapStateToProps = (state: AppStateType) => {
    return {
        name: state.auth.name
    }};


export default compose <React.ComponentType> (
    connect <MapStatePropsType, { }, { }, AppStateType> (mapStateToProps, {searchObjectThunkCreator}),
     withAuthRedirect
)(RoomsContainer);