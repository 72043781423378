import {ThunkAction} from "redux-thunk";
// import {stopSubmit} from "redux-form";
import {AppStateType} from "./redux-store";

const SET_USER_DATA = "/auth/SET-USER-DATA";
const SEARCH_OBJ = "SEARCH-OBJ"

let  initialState =  {
    isAuth: false,   /*true,*/
    isSidebarVisible : false,  /* true,*/
    level: null as number | null, /*1,*/
    name: ""  /* "Max"*/,
    // id: null as number | null,
    // country: ""
};
export type InitialStateType = typeof initialState;

const authReducer = (state = initialState, action: ActionsTypes): InitialStateType => {
    switch (action.type) {
        case "/auth/SET-USER-DATA":
            return {
                ...state,
                ...action.payload,
            };
        case "SEARCH-OBJ":
            return {
                ...state,
                ...action.searchObj,
            };
        default:
            return state;
    }
}


type SetAuthUserDataActionPayloadType = {
    isAuth: boolean
    isSidebarVisible : boolean
    level:  number
    name: string
}

type ActionsTypes = SetAuthUserDataActionType | setIdSearchDataActionType

type SetAuthUserDataActionType = {
    type: typeof SET_USER_DATA,
    payload: SetAuthUserDataActionPayloadType,
}
export const setAuthUserDataActionCreator = (level: number, name: string , isAuth: boolean,
                                             isSidebarVisible: boolean ): SetAuthUserDataActionType =>
                                    ({type: "/auth/SET-USER-DATA", payload: {level, name, isAuth, isSidebarVisible }});

type SetSearchOblType = {
    id: number|string
    country: string
}

type setIdSearchDataActionType = {
    type: typeof SEARCH_OBJ,
    searchObj:SetSearchOblType,
}

export const setIdSearchDataActionCreator = (id: number|string, country: string): setIdSearchDataActionType =>
                                    ({type: "SEARCH-OBJ", searchObj: {id, country}});

type ThunkType = ThunkAction <Promise<void>, AppStateType, unknown, ActionsTypes>

export const loginThunkCreator = (email: string, password: string):ThunkType => async (dispatch,getState) => {
    if (email === "homeho-admin" &&  password === "Y9J9rKhoU"){
        dispatch(setAuthUserDataActionCreator(1, "Admin" ,true ,true))
    }else if(email === "gowarzone" &&  password === "ugowarzoneser") {
        dispatch(setAuthUserDataActionCreator(2, "Серёга" ,true ,true))
    }else if(email === "qwerty" &&  password === "qwerty") {
        dispatch(setAuthUserDataActionCreator(3, "Роман" ,true ,true))
    }
}

export const searchObjectThunkCreator = (id: number | string, country: string):ThunkType => async (dispatch,getState) => {
    if (id) {
        dispatch(setIdSearchDataActionCreator(id, ""))
    }else if(country){
        dispatch(setIdSearchDataActionCreator("", country))
    }else if(id && country){
        dispatch(setIdSearchDataActionCreator(id, country))
    }
}



export default authReducer;