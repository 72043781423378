import React from "react";
import s from "./Header.module.css";
import PreloaderImage from "./favicon.png";

const Header: React.FC = () => {
    return (
        <div className={s.header}>
            <img src={PreloaderImage} className={s.image} alt="Preloader" />
            <h1 className={s.title}>HOMEHO</h1>
            <h1 className={s.title2}>админка</h1>
        </div>
    );
}

export default Header;
