import React from 'react';
import styles from "./ObjectTabele.module.css";
import ObjectTabeleRow from './ObjectTabeleRow/ObjectTabeleRow';

type TableProps = {
 data: Ar[];
}

interface Ar {
  number: number;
  idx: string;
  city: string;
  street: string;
  price: number;
  square: number;
}


const ObjectTabele: React.FC<TableProps> = ({data}) => {

  return (
    <table className={styles.table}>
    <colgroup>
    <col className={styles.num}/>
    <col className={styles.idx}/>
    <col className={styles.city}/>
    <col className={styles.street}/>
    <col className={styles.price}/>
    <col className={styles.square}/>
    </colgroup>
    <thead>
        <tr>
            <th>№</th>
            <th>ID</th>
            <th>Город</th>
            <th>Адрес</th>
            <th>Цена</th>
            <th>М<sup>2</sup></th>
        </tr>
    </thead>
    <tbody>
      {
        data.map(row=>
          <ObjectTabeleRow
          key={row.number}
          number={row.number}
          idx={row.idx}
          city={row.city}
          street={row.street}
          price={row.price}
          square={row.square}
          />
        )
      }
    </tbody>
</table>

  )
}

export default ObjectTabele;