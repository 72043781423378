import React, { useEffect, useState } from "react";
import s from "./Parser.module.css";
import Axios from "axios";

type PropsType = {
    name: string
}

const Parser : React.FC<PropsType>  = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isGeoParser, setIsGeoParser] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [logsPerPage, setLogsPerPage] = useState(10);
  
    console.log(setLogsPerPage);
    
    useEffect(() => {
      setIsLoading(true);
      // Axios.get('http://localhost:3030/v1/room/get-log-parsers')
      Axios.get('https://homeho.ru/api/v1/room/get-log-parsers')
        .then(response => {
          console.log(response, 'resp');
          const sortedData = response.data.sort((a: any, b: any) => {
            let startTime: any = new Date(b.start_time);
            let endTime: any = new Date(a.start_time);
            return startTime - endTime;
          });
          setData(sortedData);
          setIsLoading(false);
        })
        .catch(error => {
          console.error(error);
          setIsLoading(false);
        });
    }, []);

    const handleGetData = () => {
        setIsLoading(true);
        // Axios.get('http://localhost:3030/v1/room/launch-parsers')
        Axios.get('https://homeho.ru/api/v1/room/launch-parsers')
          .then(response => {
            console.log(response, 'resp');
            
            setData(response.data);
            setIsLoading(false);
          })
          .catch(error => {
            console.error(error);
            setIsLoading(false);
          });
      };

    // const cancelParser = () => {
    //     Axios.get("http://localhost:3030/v1/room/get-geo")
    //       .then((response) => {
    //         console.log(response.data);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   };
  
    const openHTMLFile = () => {
      window.open("/geo-parser.html", "_blank");
      setIsGeoParser(true);
      // Axios.get('http://localhost:3030/v1/room/parser-geo')
      Axios.get('https://homeho.ru/api/v1/room/parser-geo')
        .then(response => {
          setData(response.data);
          setIsGeoParser(false);
        })
        .catch(error => {
          console.error(error);
          setIsGeoParser(false);
        });
    };

    const indexOfLastLog = currentPage * logsPerPage;
    const indexOfFirstLog = indexOfLastLog - logsPerPage;
    const currentLogs = data.slice(indexOfFirstLog, indexOfLastLog);

    const renderLogs = currentLogs.map((log: any, index) => {
      let status;
      switch (log.status) {
        case 1:
          status = "Successful";
          break;
        case 2:
          status = "Error";
          break;
        case 3:
          status = "Already exists";
          break;
        default:
          status = "";
          break;
      }

      return (
        <tr key={index}>
          <td>{log.file_name}</td>
          <td>{log.parser_name}</td>
          <td>{log.start_time}</td>
          <td>{log.end_time}</td>
          <td>{status}</td>
        </tr>
      );
    });
  
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / logsPerPage); i++) {
      pageNumbers.push(i);
    }
  
    // const renderPageNumbers = pageNumbers.map(number => {
    //   return (
    //     <li
    //       key={number}
    //       className={`${s.pageNumber} ${
    //         currentPage === number ? s.active : ""
    //       }`}
    //     >
    //       <button onClick={() => setCurrentPage(number)}>{number}</button>
    //     </li>
    //   );
    // });

    const renderPageNumbers = pageNumbers.map((number, index) => {
      if (number === 1 || number === pageNumbers.length || Math.abs(currentPage - number) <= 1) {
        // show first, last, and adjacent pages
        return (
          <li
            key={index}
            className={`${s.pageNumber} ${
              currentPage === number ? s.active : ""
            }`}
          >
            <button onClick={() => setCurrentPage(number)}>{number}</button>
          </li>
        );
      } else if (index === 1 || index === pageNumbers.length - 2) {
        // show ellipsis at the second and second-to-last positions
        return <li key={index}>...</li>;
      } else {
        // hide other pages
        return null;
      }
    });
    

    return (
      <div className={s.container}>
        <div className={s.block__text}>
          <div className={s.text}>
          </div>
        </div>
        <div>
          <button onClick={handleGetData} disabled={isLoading} className={s.button}>
            {isLoading ? 'Loading...' : 'Парсер обьектов'}
          </button>
          <button onClick={openHTMLFile} disabled={isGeoParser} className={s.button}>
            {isGeoParser ? 'Loading' : 'Geo парсер'}
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>File name</th>
              <th>Parser name</th>
              <th>Start date</th>
              <th>End date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {renderLogs}
          </tbody>
        </table>
        <ul className={s.pageNumbers}>
          {renderPageNumbers}
        </ul>
      </div>
    );
  }
  
  export default Parser;