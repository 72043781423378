import React from 'react';
import './App.css';
import {Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router";
import "antd/dist/antd.css";
import Login from "./components/Login/Login";
import {Redirect} from "react-router-dom";
import ProfileContainer from "./components/Profile/ProfileContainer";
import RoomsContainer from "./components/Rooms/RoomsContainer"
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import {AppStateType} from "./redux/redux-store";
import NewsContainer from "./components/News/NewsContainer";
import ParserContainer from './components/ParserCSV/ParserContainer';
import ObjectStatusContainer from './components/ObjectStatus/ObjectStatusContainer'

type MapPropsType = ReturnType<typeof mapStateToProps>

class App extends React.Component <MapPropsType> {

    render() {

        return (
            <div className="app-wrapper">
                <div className="header">
                    { this.props.isSidebarVisible ? <Header /> : null}
                </div>
                <div className="app__container">
                    <div className="app__navbar">
                        { this.props.isSidebarVisible ? <Navbar /> : null}
                    </div>
                    <div className="app__content">
                        <Switch>
                            <Route exact path='/'
                                   render={() => <Redirect to={"/login"} /> } />
                            <Route path='/login' render={() => <Login />}/>

                            <Route path='/profile' render={() => <ProfileContainer/>}/>
                            <Route exact path='/objects' render={() => <RoomsContainer />}/>
                            <Route exact path='/news' render={() => <NewsContainer />}/>
                            <Route exact path='/parser' render={() => <ParserContainer />} />
                            <Route exact path='/object_status' render={() => <ObjectStatusContainer />} />

                            <Route path='*' render={() => <div>404 NOT FOUND</div>}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: AppStateType) => ({
    isSidebarVisible: state.auth.isSidebarVisible
})

export default compose(
    withRouter,
    connect(mapStateToProps, {
         })) (App);