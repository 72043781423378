import React from "react";
import s from "./Profile.module.css";

type PropsType = {
    name: string

}

const Profile : React.FC<PropsType>  = (props) => {
    return (
        <div className={s.container}>
            <div className={s.block__text}>
                {/* <h1 className={s.h}>Рады Вас видеть {props.name}!</h1> */}
                <div className={s.text}>
                    {/* <p>
                        Контент 1
                    </p> */}
                </div>
            </div>
        </div>
    );
}


export default Profile;