import React from "react";
import s from "./Rooms.module.css";
import FilterObjectReduxForm from "./FilterObject/FilterObject";
import Object from "./Object/Object";
// import {Redirect} from "react-router-dom";

type PropsType = {
    name: string

}

type FilterObjectFormValuesType = {
    id: string | number
    country: string
}
type MapDispatchPropsType = {
    searchObjectThunkCreator: (id: string | number, country: string ) => void
}

const Rooms : React.FC<PropsType & MapDispatchPropsType> = (props) => {

    const onSubmit = (formData: FilterObjectFormValuesType) => {
        props.searchObjectThunkCreator(formData.id, formData.country)
    }

    return (
        <div className={s.container}>
            <div className={s.block__text}>
                {/* <h1 className={s.h}>Рады Вас видеть {props.name}!</h1> */}
                <div className={s.text}>
                    <p>
                        <FilterObjectReduxForm onSubmit={onSubmit}/>
                        <Object />
                    </p>
                </div>
            </div>
        </div>
    );
}


export default Rooms;